<template></template>

<script>
import store from '@/store';
import routes from '@/store/modules/routes';

export default {
  name: 'Logout',
  mounted() {
    store.dispatch('logout');
    store.dispatch('resetSelectedCompany');

    if (window.Tawk_API) {
      this.closeTawkTo();
    }

    this.$router.replace({
      name: routes.state.appRoutes['LOGIN']
    });
  },
  methods: {
    closeTawkTo() {
      this.$Tawk.$endChat();
      this.$Tawk.$logout();
      this.$Tawk.$hideWidget();
      this.$Tawk.$shutdown();
    }
  }
};
</script>
